<template>
  <div class="tabulation">
    <el-card class="chart-card">
      <!-- 搜索框 -->
      <div class="grabble">
        <!-- <div style="width: 57%;display: flex;align-items: center;">
          <div>
            <el-date-picker
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                size="large"
                @change="immediUpdate"
          />
          </div>
            <el-select
              class="hunt"
              v-model="ability"
              placeholder="请选择组织名称"
              size="large"
              @click="multiple"
              clearable
              @change="numerical"
            />
          <el-button style="margin-left: 10px" type="primary" size="large" @click="search"
            ><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button
          >
        </div> -->
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        @cell-mouse-enter="obtain"
        border
        :empty-text="texts"
        :header-cell-style="{ background: '#F5F7FA' }"
      >
        <!-- 会员编码 -->
        <el-table-column
          label="会员编码"
          prop="username"
          align="center"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 积分额度 -->
        <el-table-column
          label="积分额度"
          prop="integralValue"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 变动类型 -->
        <el-table-column
          label="变动类型"
          prop="type"
          align="center"
          :formatter="formatSex"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 备注 -->
        <el-table-column
          label="备注"
          prop="reason"
          align="center"
          :formatter="quantum"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
        <!-- 变动时间   -->
        <el-table-column
          label="变动时间  "
          prop="createTime"
          align="center"
          :formatter="tiems"
          :show-overflow-tooltip='true'
        >
        </el-table-column>
      </el-table>
      <!-- 表格分页 -->
      <!-- 一页时候隐藏 hide-on-single-page="true" -->
      <el-pagination
        hide-on-single-page="true"
        layout="prev, pager, next,sizes"
        :page-sizes="[6, 12, 18, 24]"
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        :total="altogether"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
     <!-- 所属组织弹窗 -->
    <div>
      <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']" @yes="yes">
        <el-tree
          :data="texture"
          :props="defaultProps"
          accordion
          @node-click="dendrogram"
        />
      </s3-layer>
    </div>
  </div>
</template>
<script>
// import { Search } from "@element-plus/icons-vue";
import { useRouter,
		useRoute  } from "vue-router";
import { ref,onMounted,onActivated } from 'vue';
import { YEintegral,Zzlist } from '../../utils/api'
import qs from 'qs'
import moments from 'moment'
// import { ElMessage } from "element-plus";
export default {
  name:"YEintegral",
  setup() {
	  const route = useRoute();
	  let translate = ref(2);
	  onActivated(() => {
	  	translate.value = route.params.UserId;
	  	if (translate.value == 1) {
	  		list();
	  	}
	  });
    // 判断类型
    let formatSex = (row, column, cellValue) => {
      if (cellValue == 1) {
        return "增加积分";
      } else if (cellValue === 2) {
        return "扣减积分";
      }
    };




    const zong =ref(null)
    // 工会清空
    const numerical=(e)=>{
      if(e==''){
        Hcode.value=''
      }
    }

    
        // 获取code 
    let Hcode=ref('')
    // 搜索组织选择值
    let ability=ref('')
    // 弹窗树形选择
    let dendrogram = (node)=>{
      ability.value=node.unionName
      Hcode.value=node.unionCode
      console.log(node)
    }
    const lang=()=>{
      Zzlist().then((res)=>{
        // console.log(res)
        texture.value=res.data.data.wholeUnionList
      })
    }
    // 弹窗组织列表树形
    let texture =ref([])
    const defaultProps = {
      children: 'unionDtoList',
      label: 'unionName',
    }
     // 选择组织按钮
    let multiple =()=>{
      visible.value=true
    }
    // 搜索组织弹窗
    let visible=ref(false)





    // 时间转换
    let a =ref('')
    let b =ref('')

    // 时间清空监听
    const immediUpdate=(e)=>{
      if(e!==null){
        a.value=new Date(e[0]).getTime()/1000
        b.value=new Date(e[1]).getTime()/1000
      }else if(e==null){
        a.value=''
        b.value=''
      }
      console.log(e)
    }
    // 时间选择
    let value1=ref('')





    // 列表内容显示
    let texts= ref('加载中')
    // 搜索框值变化清空
    let orangutan=()=>{
      // if(topic.value==''&&values.value==''){
      //   list()
      // }
    }
    // 搜索
    let search=()=>{
      console.log(Hcode.value)
      let data={
        limit:branches.value,
        page:currentPage.value,
        startTime:a.value,
        endTime:b.value,
        unionCode:Hcode.value
      }
      console.log(data)
      YEintegral(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          altogether.value=res.data.data.page.totalCount
          filterTableData.value=res.data.data.page.list
          
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
          
        }
      })
    }
    // 分页
    let currentPage=ref(1)
    let pagesize=ref(6)
    let handleSizeChange=(size)=>{
      console.log(console.log(ability.value))
      pagesize.value=size
      branches.value=size
      list()
      // if(a.value!==''||b.value!==''||ability.value!==''){
      //   search()
      // }else{
      //   list()
      // }
    }
    let handleCurrentChange=(size)=>{
      console.log(ability.value)
      currentPage.value=size
      list()
      // if(a.value!==''||b.value!==''||ability.value!==''){
      //   search()
      // }else{
      //   list()
      // }
    }
    // 时间转换
    let tiems=(row, colum,cellValue )=>{
      if(typeof cellValue=='number'){
        return moments(cellValue*1000).format().substring(0,10)
      }
    }
    // 总条数
    let altogether=ref(null)
    // 工会动态列表
    let branches=ref(6)
    const list =()=>{
      let data ={
        limit:branches.value,
        page:currentPage.value,
        username:sessionStorage.getItem('processIds')
      }
      YEintegral(qs.stringify(data)).then((res)=>{
        console.log(res)
        if(res.data.code==0){
          altogether.value=res.data.data.integralChangeLog.totalCount
          filterTableData.value=res.data.data.integralChangeLog.list
          if(filterTableData.value.length==0){
            texts.value='暂无数据'
          }
        }
      })
    }
    onMounted(()=>{
      list()
      lang()
    })
    // 头部搜索
    let values=ref('')
    // 路由跳转
    // const router = useRouter()
    // 列表数据循环
    const filterTableData = ref([])
    return {
      formatSex,
      zong,
      numerical,
      ability,
      texture,
      defaultProps,
      dendrogram,
      visible,
      multiple,
      immediUpdate,
      value1,
      texts,
      orangutan,
      // 搜索
      search,
      // 分页
      handleSizeChange,
      handleCurrentChange,
      altogether,
      pagesize,
      currentPage,
      tiems,
      values,
      // 循环列表数据
      filterTableData
    };
  },
  components: {
    // Search
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dsa{
  margin-left: 10px !important;
}
::v-deep .asd{
  margin-right: 10px !important;
}
::v-deep .chart-card::-webkit-scrollbar{
  display:none
}
.chart-card{
  height: calc(100vh - 150px);
  overflow: auto;
}
.summation{
  display: flex;
  justify-content: space-between;
}
::v-deep  .el-table__empty-block{
  border-bottom: 1px solid #EBEEF5 !important;
}
.hint{
  font-size: 14px;
  color: #909399;
  // font-weight: bold;
  margin-bottom: 15px;
}
.el-select--large{
  width: 20%;
  // margin-left: 10px;
}
::v-deep .el-table__body {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt {
  width: 22%;
  margin-right: 10px;
  margin-left: 10px;
}
.grabble {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.el-table__header {
  margin-top: 0;
  margin-bottom: 0;
}
.red {
  color: red;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.red:hover {
  color: #fab6b6;
}
.bulur {
  color: #409eff;
  border: none;
  --el-button-bg-color: none;
  --el-button-hover-bg-color: none;
  --el-button-hover-text-color: none;
}
.bulur:hover {
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb {
  background-color: #ffffff;
}
.items {
  display: flex;
}
.el-select .el-input__inner {
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
::v-deep .el-scrollbar__thumb {
  display: none !important;
}
</style>
<style>
/* ::-webkit-scrollbar{display:none} */
.el-popper {
  z-index: 10000 !important;
}
.modality {
  height: 150px !important;
}

.el-select-dropdown__item.selected {
  font-weight: 500 !important;
}
</style>
